import React from 'react';
// import RingLoader from 'react-spinners/RingLoader.js'
// import RiseLoader from 'react-spinners/RiseLoader.js'
import BeatLoader from 'react-spinners/BeatLoader.js'


const LoaderSpinner = () => {


    return (
        <div>
            <div id="loader" className='loader'>
                <div className="loader__frame">
                    <div className="gauge">
                        <div className="gauge__content">
                            {/* <RingLoader
                            // color="#26C8A1"
                            // color="#1354e2"
                            color="tomato"
                            size={100}
                            speedMultiplier={0.6}
                            /> */}
                            <BeatLoader color="teal" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default LoaderSpinner;




