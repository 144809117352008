import React, { useEffect, useState } from 'react';
import LoaderSpinner from '../components/LoaderSpinner.js';

const MainComponent = React.lazy(() => import('../components/Main.js'));

const Home = () => {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    })

    return loader ? (
        <LoaderSpinner/>
      ):(
        <MainComponent />
      )
};

export default Home;